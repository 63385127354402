import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import moment from 'moment'

import { logout } from '../../store/actions/authActions'


import bodyClassController from '../../store/utils/bodyClassController'
import PrimaryMenu from '../../components/menu/headerPrimaryMenu'
import SideMenu from '../../components/menu/sideMenu'
import PageBreadcrumb from '../../components/default/pageBreadcrumb'

import setPageData from '../../store/actions/setPageData'
import { retrieveUserDetails, updateUserAction, deleteUserAction } from '../../store/actions/userActions'

class UserUpdatePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			UserID: this.props.match.params.UserID,
			userGuaranteedAccessTill: '',
			error: {},
			isLoading: false,
		}
	}
	changeHandler = event => {
		if (event.target.type == 'checkbox') {
			this.setState({
				[event.target.name]: event.target.checked
			})
		} else {
			this.setState({
				[event.target.name]: event.target.value
			})
		}
	}

	submitHandler = event => {
		if (event != null) {
			event.preventDefault()
		}

		// Get user Inputted value
		let { UserID, userGuaranteedAccessTill } = this.state

		// Get from old user data
		if (!userGuaranteedAccessTill) {
			let { userData } = this.props
			let userDetails = userData?.userDetails

			userGuaranteedAccessTill = userDetails?.guaranteedAccessTill
		}

		// format the date //
		if (userGuaranteedAccessTill) {
			let format = 'YYYY-MM-DD'
			let datum = (Date.parse(userGuaranteedAccessTill));
			userGuaranteedAccessTill = moment(datum).format(format) + ' 23:59:59'
		}

		let data = {
			UserID,
			_uItem: {
				guaranteedAccessTill: userGuaranteedAccessTill
			}
		}

		this.setState({ isLoading: 1 })
		this.props.updateUserAction(data, this.props.history, this.stopLoading, this.resetFormData)

		let { page } = this.state
	}

	deleteUser = () => {
		var answer = window.confirm("Are you sure you want to delete?");

		if (answer) {
			// Get user Inputted value
			let { UserID, userGuaranteedAccessTill } = this.state
			let data = { UserID }

			this.setState({ isLoading: 2 })
			this.props.deleteUserAction(data, this.props.history, this.stopLoading, this.resetFormData)

			let { page } = this.state
		}
	}

	stopLoading = () => {
		this.setState({ isLoading: false })
	}
	resetFormData() {

	}

	setPageData() {
		this.props.setPageData({
			title: 'User update',
			pageID: 'users'
		});
	}


	loadUserDetails() {
		let { UserID } = this.state
		this.props.retrieveUserDetails({ UserID, U_Status: 'any' })
	}
	componentDidMount() {
		this.setPageData()
		this.loadUserDetails()
	}

	componentWilMount() {
	}

	render() {
		this.props.bodyClassController(this.props.pageData)

		let { userData, pageData } = this.props
		let { userGuaranteedAccessTill, isLoading } = this.state

		let userDetails = userData?.userDetails

		if (!userGuaranteedAccessTill) {
			let guaranteedAccessTill = userDetails?.guaranteedAccessTill
			let format = 'YYYY-MM-DD'
			let datum = (Date.parse(guaranteedAccessTill));

			userGuaranteedAccessTill = moment(datum).format(format)

			// this.setState({ userGuaranteedAccessTill })
		}

		return (
			<>
				<Helmet> <title>{pageData.title}</title> </Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
								</div>

								<div className="page-content">
									{userDetails ?
										<div className="row">
											<div className="col-md-6 col-12">
												<h5 className="card-title">{`${userDetails.firstName} ${userDetails.lastName}`}</h5>
												<form onSubmit={this.submitHandler}>
													<div className="form-group">
														<label htmlFor="userGuaranteedAccessTill">Guaranteed access till</label>
														<input type="date" className="form-control"
															onChange={this.changeHandler}
															name="userGuaranteedAccessTill"
															id="userGuaranteedAccessTill" value={userGuaranteedAccessTill} />
														<small id="emailHelp" className="form-text text-muted">User will get access till that date without any active paid plan</small>
													</div>
													{isLoading === 1 ?
														<button type="button" className="btn btn-danger"><i className="fas fa-spinner fa-spin"></i></button>
														:
														<button type="submit" className="btn btn-danger">Update</button>
													}
												</form>
											</div>
											<div className="col-md-6 col-12 mt-4">
												<h5 className="card-title">Actions</h5>
												<div className="">
													{isLoading === 2 ?
														<button type="button" className="btn btn-danger"><i className="fas fa-spinner fa-spin"></i></button>
														:
														<button type="button" onClick={this.deleteUser} className="btn btn-danger">Delete Account</button>
													}
												</div>
											</div>
										</div>
										: <div className="text-warning">Please wait...</div>}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}


	openReOrderModal = orderInfo => {
		this.setState({
			ReOrderModalOpen: orderInfo
		})
	}
	closeReOrderModal = () => {
		this.setState({
			ReOrderModalOpen: null
		})
	}



	openNewTicketModal = (item) => {
		this.setState({
			NewTicketModalOpen: item.OrderID
		})
	}

	closeNewTicketModal = () => {
		this.setState({
			NewTicketModalOpen: false
		})
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
})
const mapDispatchToProps = ({
	logout,
	setPageData,
	bodyClassController,
	retrieveUserDetails,
	updateUserAction,
	deleteUserAction,
})
export default connect(mapStateToProps, mapDispatchToProps)(UserUpdatePage)