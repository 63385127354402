import React from 'react'
import { Helmet } from 'react-helmet'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/authActions'
import ReactPaginate from 'react-paginate';
import Icofont from 'react-icofont';
import { ViewDate, ViewExactDate } from '../../utils/DateViewer'


import bodyClassController from '../../store/utils/bodyClassController'
import PrimaryMenu from '../../components/menu/headerPrimaryMenu'
import SideMenu from '../../components/menu/sideMenu'
import PageBreadcrumb from '../../components/default/pageBreadcrumb'
import NewPromoModal from '../../components/promoCode/NewPromoModal'

import setPageData from '../../store/actions/setPageData'
import { retrieveUsers } from '../../store/actions/userActions'

class UserDetailsPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
		}
	}
	changeHandler = event => {
		if (event.target.type == 'checkbox') {
			this.setState({
				[event.target.name]: event.target.checked
			})
		} else {
			this.setState({
				[event.target.name]: event.target.value
			})
		}
	}

	submitHandler = event => {
		if (event != null) {
			event.preventDefault()
		}

		let { page } = this.state
		// this.props.retrieveUsers({ page })
	}

	setPageData() {
		this.props.setPageData({
			title: 'User details',
			pageID: 'users'
		});
	}

	componentDidMount() {
		this.setPageData()
	}

	componentWilMount() {
	}

	render() {
		this.props.bodyClassController(this.props.pageData)

		let { userData, pageData } = this.props
		let { page } = this.state

		return (
			<>
				<Helmet> <title>{pageData.title}</title> </Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
								</div>

								<div className="page-content">
									Coming soon...
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}


	openReOrderModal = orderInfo => {
		this.setState({
			ReOrderModalOpen: orderInfo
		})
	}
	closeReOrderModal = () => {
		this.setState({
			ReOrderModalOpen: null
		})
	}



	openNewTicketModal = (item) => {
		this.setState({
			NewTicketModalOpen: item.OrderID
		})
	}

	closeNewTicketModal = () => {
		this.setState({
			NewTicketModalOpen: false
		})
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
})
const mapDispatchToProps = ({
	logout,
	setPageData,
	bodyClassController,
	retrieveUsers,
})
export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsPage)