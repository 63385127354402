import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/authActions'
import ReactPaginate from 'react-paginate';
import Icofont from 'react-icofont';
import { ViewDate, ViewExactDate } from '../../utils/DateViewer'


import bodyClassController from '../../store/utils/bodyClassController'
import PrimaryMenu from '../../components/menu/headerPrimaryMenu'
import SideMenu from '../../components/menu/sideMenu'
import PageBreadcrumb from '../../components/default/pageBreadcrumb'
import NewPromoModal from '../../components/promoCode/NewPromoModal'

import setPageData from '../../store/actions/setPageData'
import { retrieveAffiliates } from '../../store/actions/userActions'

class AffiliatesPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ReOrderModalOpen: null,
			expandedItem: null,
			NewTicketModalOpen: false,
			user: '',
			password: '',
			affiliate: true,
			keyword: '',
			error: {},
			page: 1,
		}
	}
	changeHandler = event => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	submitHandler = event => {
		if (event != null) {
			event.preventDefault()
		}

		let { page, keyword, affiliate } = this.state

		this.props.retrieveAffiliates({ page, keyword, affiliate, viewType: 'affiliate' })
	}

	setPageData() {
		this.props.setPageData({
			title: 'Affiliates',
			pageID: 'affiliates'
		});
	}

	componentDidMount() {
		this.setPageData()
		this.submitHandler()
	}

	componentWilMount() {
	}

	handlePagination = data => {
		this.setState({
			page: data.selected + 1,
		}, () => {
			this.submitHandler()
		});
	}

	render() {
		this.props.bodyClassController(this.props.pageData)

		let { affiliateData, pageData } = this.props
		let { keyword, expandedItem } = this.state
		let allUsers, userPagination, showPagination;
		if (affiliateData && affiliateData.data) {
			allUsers = affiliateData.data.length ? affiliateData.data : null
			userPagination = affiliateData.pagination ? affiliateData.pagination : null

			showPagination = userPagination.page_last > 1 ? true : false
		}

		return (
			<>
				<Helmet> <title>{pageData.title}</title> </Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
										<Link to='/affiliates/new' className="btn btn-primary ml-3">
											<span className="icon-wrapper">
												<Icofont icon="icofont-plus-circle" />
											</span>
											<span className="icon-text">Add New</span>
										</Link>
									</div>
								</div>

								<div className="page-content">
									<div className="table-area table-responsive">
										<div className="table-filter">
											<form action="" onSubmit={this.submitHandler}>

												<input type="text" id="keyword"
													name="keyword"
													value={keyword}
													onChange={this.changeHandler}
													placeholder="Search.." />
												<button type="submit" className="ml-1 btn-primary">Search</button>
											</form>
										</div>
										<table className="table data-table table-hover table-light">
											<thead>
												<tr>
													<th scope="col">name</th>
													<th scope="col">ID</th>
													<th scope="col">email</th>
													<th scope="col">date</th>
													<th scope="col">promo</th>
													<th scope="col">athletes</th>
												</tr>
											</thead>

											<tbody>
												{
													allUsers ? allUsers.map(item => {
														let userDisplayName = item.name ? item.name : `${item.firstName} ${item.lastName}`
														return (
															<tr
																key={item.UserID}
																onClick={() => this.setState({ 'expandedItem': item.UserID })}
																className={` single-item ${expandedItem == item.UserID ? 'expanded' : ''}`} >

																<td data-label={`${expandedItem == item.UserID ? 'Name' : '#' + item.UserID}`}>{userDisplayName}</td>
																<td data-label="UserID">{`#${item.UserID}`}</td>
																<td data-label="Email">{item.email}</td>
																<td data-label="Register"><ViewDate date={item.date} /></td>
																<td data-label="Promo">{item.promoCodes}</td>
																<td data-label="Athletes">{item.affiliateUsers}</td>
															</tr>
														)
													})
														: ''
												}
											</tbody>
										</table>
										{
											showPagination ?
												<div className="section-pagination">
													<font>Showing {userPagination.data_start} to {userPagination.data_end} out of {userPagination.data_total}</font>
													<nav
														className="pagination-nav"
														aria-label="...">
														<ReactPaginate
															previousLabel={'previous'}
															nextLabel={'next'}
															breakLabel={'...'}
															breakClassName={'break-me'}
															pageCount={userPagination.page_last}
															initialPage={(userPagination.page - 1)}
															marginPagesDisplayed={2}
															pageRangeDisplayed={5}
															containerClassName={'pagination'}
															previousClassName={'page-item'}
															nextClassName={'page-item'}
															pageClassName={'page-item'}
															subContainerClassName={'pages pagination'}
															activeClassName={'active'}
															pageLinkClassName={'page-link'}
															previousLinkClassName={'page-link'}
															nextLinkClassName={'page-link'}
															onPageChange={this.handlePagination}
														/>
													</nav>
												</div>

												: ''
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}


	openReOrderModal = orderInfo => {
		this.setState({
			ReOrderModalOpen: orderInfo
		})
	}
	closeReOrderModal = () => {
		this.setState({
			ReOrderModalOpen: null
		})
	}



	openNewTicketModal = (item) => {
		this.setState({
			NewTicketModalOpen: item.OrderID
		})
	}

	closeNewTicketModal = () => {
		this.setState({
			NewTicketModalOpen: false
		})
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	affiliateData: state.affiliateData,
})
const mapDispatchToProps = ({
	logout,
	setPageData,
	bodyClassController,
	retrieveAffiliates,
})
export default connect(mapStateToProps, mapDispatchToProps)(AffiliatesPage)