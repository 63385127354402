import * as Types from '../actions/types'

const init = {
	data: {},
	userDetails: {},
	pagination: {},
	error: {},
	selected: {},
	success: '',
}

const userReducer = (state = init, action) => {
	switch (action.type) {

		case Types.USER_RETRIEVE: {
			return {
				...state,
				data: action.payload.response.data,
				pagination: action.payload.response.pagination,
				selected: {},
				error: {},
			}
		}
		case Types.USER_DETAILS: {
			return {
				...state,
				userDetails: action.userData,
			}
		}

		default: return state
	}
}

export default userReducer