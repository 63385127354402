import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import LoginPage from './pages/auth/LoginPage'

import UsersPage from './pages/user/UsersPage'
import PromoCodesPage from './pages/promo-code/PromoCodesPage';
import NewPromoCodePage from './pages/promo-code/NewPromoCodePage';

import authChecking from './components/default/authChecking'


import { connect } from 'react-redux'

import { isLoggedin } from './store/actions/authActions'

import configureAxios from './store/utils/configureAxios'


import PrimaryMenu from './components/menu/headerPrimaryMenu'
import FooterPrimary from './components/footer/footerPrimary'
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import AffiliatesPage from './pages/affiliate/AffiliatesPage';
import NewAffiliatePage from './pages/affiliate/NewAffiliatePage';
import UserDetailsPage from './pages/user/UserDetailsPage';
import UserUpdatePage from './pages/user/UserUpdatePage';


class Routes extends React.Component {
	state = {
		user: '',
		password: '',
		error: {},
	}
	async componentWillMount() {
		configureAxios() // Run Basic Axios Configure
	}

	componentDidMount() {
	}

	render() {

		// let { user, password, error } = this.state
		let { isAuthenticated } = this.props.auth
		return (
			<BrowserRouter>
				<Switch>
					<Route path="/" exact component={isAuthenticated ? UsersPage : authChecking} />

					<Route path="/login" component={LoginPage} />
					<Route path="/forgot-password" component={ForgotPasswordPage} />
					<Route path="/reset-password" component={ResetPasswordPage} />

					<Route path="/users" exact component={isAuthenticated ? UsersPage : authChecking} />
					<Route path="/users/:UserID" exact component={isAuthenticated ? UserDetailsPage : authChecking} />
					<Route path="/users/:UserID/update" exact component={isAuthenticated ? UserUpdatePage : authChecking} />

					<Route path="/affiliates" exact component={isAuthenticated ? AffiliatesPage : authChecking} />
					<Route path="/affiliates/new" exact component={isAuthenticated ? NewAffiliatePage : authChecking} />
					<Route path="/promo-code" exact component={isAuthenticated ? PromoCodesPage : authChecking} />
					<Route path="/promo-code/new" exact component={isAuthenticated ? NewPromoCodePage : authChecking} />
				</Switch>
			</BrowserRouter>
		)
	}
}

// export default App;

const mapStateToProps = state => ({
	auth: state.auth
})
const mapDispatchToProps = ({
	isLoggedin,
	PrimaryMenu,
	FooterPrimary,
})
export default connect(mapStateToProps, mapDispatchToProps)(Routes)