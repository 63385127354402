import * as Types from '../actions/types'

const init = {
	isAuthenticated: false,
	authChecking: false,
	user: {},
	error: {},
	userBalance: 0,
}

const authReducer = (state = init, action) => {
	switch (action.type) {
		case Types.SET_USER: {
			return {
				...state,
				user: action.payload.user,
				isAuthenticated: Object.keys(action.payload.user).length !== 0,
				error: {},
				authChecking: false,
				userBalance: action.payload.user.U_Balance,
			}
		}

		case Types.USERS_ERROR: {
			return {
				...state,
				error: action.payload.error
			}
		}

		case Types.USERS_AUTH_CHECKING: {
			return {
				...state,
				authChecking: action.payload.status
			}
		}
		default: return state
	}
}

export default authReducer