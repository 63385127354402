import { combineReducers } from 'redux'
import pageDataReducer from './pageDataReducer'
import authReducer from './authReducer'
import userReducer from './userReducer'
import promoReducer from './promoReducer'
import affiliateReducer from './affiliateReducer';

const rootReducer = combineReducers({
	pageData: pageDataReducer,
	userData: userReducer,
	promoData: promoReducer,
	auth: authReducer,
	affiliateData: affiliateReducer,
})

export default rootReducer