import axios from 'axios'
import * as Types from './types'

import { manageError } from '../utils/axiosResponseError'
import { showSuccess, showError } from '../utils/configToast'

export const retrievePromoCodes = (data) => dispatch => {
	axios.post('/user/promo-code/list', data)
		.then((res) => {
			let responseData = res.data

			dispatch({
				type: Types.PROMO_RETRIEVE,
				payload: {
					response: responseData,
					error: {}
				}
			})
		})
		.catch(error => {
			manageError(error)
		})
}

export const insertPromoCodes = (data, history, stopLoading, resetFormData) => dispatch => {
	axios.post('/user/promo-code/insert', data)
		.then((res) => {
			stopLoading()
			resetFormData()
			let promoInfo = res.data.data

			showSuccess('Successfully created');

			dispatch({
				type: Types.PROMO_NEW,
				payload: {
					promoInfo,
					error: {}
				}
			})
		})
		.catch(error => {
			stopLoading()
			manageError(error)
		})
}