export const SET_USER = 'SET_USER'
export const USERS_ERROR = 'USERS_ERROR'
export const USERS_AUTH_CHECKING = 'USERS_AUTH_CHECKING'


export const USER_RETRIEVE = 'USER_RETRIEVE'
export const USER_DETAILS = 'USER_DETAILS'

export const PROMO_RETRIEVE = 'PROMO_RETRIEVE'
export const PROMO_NEW = 'PROMO_NEW'

export const AFFILIATE_RETRIEVE = 'AFFILIATE_RETRIEVE'
export const AFFILIATE_NEW = 'AFFILIATE_NEW'

export const SET_PAGE_DATA = 'SET_PAGE_DATA'