import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/authActions'

import Icofont from 'react-icofont';


class SideMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: '',
			password: '',
			error: {},
		}
	}

	changeHandler = event => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	submitHandler = event => {
		event.preventDefault()

		this.props.logout(this.props.history)
	}

	render() {
		let { pageID } = this.props.pageData
		return (
			<div className="col-md-2 side-menu">
				<nav className="collapse show sidebar-nav pt-4" id="left-nav">

					<div className={(pageID === 'users' ? "nav-item active" : "nav-item")}>
						<Link to='/users' className="nav-link">
							<span className="icon-wrapper">
								<Icofont icon="icofont-users-alt-4" />
							</span>
							<span className="icon-text">Users</span>
						</Link>
					</div>
					<div className={(pageID === 'affiliates' ? "nav-item active" : "nav-item")}>
						<Link to='/affiliates' className="nav-link">
							<span className="icon-wrapper">
								<Icofont icon="icofont-users-alt-2" />
							</span>
							<span className="icon-text">Affiliates</span>
						</Link>
					</div>
					<div className={(pageID === 'promo-code' ? "nav-item active" : "nav-item")}>
						<Link to='/promo-code' className="nav-link">
							<span className="icon-wrapper">
								<Icofont icon="icofont-plus-circle" />
							</span>
							<span className="icon-text">Promo code</span>
						</Link>
					</div>
				</nav>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
})
const mapDispatchToProps = ({
	logout
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideMenu))