import axios from 'axios'
import * as Types from './types'

import { showSuccess, showError } from '../utils/configToast'
import { manageError } from '../utils/axiosResponseError'

export const retrieveUsers = (data) => dispatch => {
	axios.post('/user/list', data)
		.then((res) => {
			let responseData = res.data

			dispatch({
				type: Types.USER_RETRIEVE,
				payload: {
					response: responseData,
					error: {}
				}
			})
		})
		.catch(error => {
			manageError(error)
		})
}

export const retrieveUserDetails = (data) => dispatch => {

	dispatch({
		type: Types.USER_DETAILS,
		userData: null
	})

	axios.post('/user/list', data)
		.then((res) => {
			let userData = res?.data?.data[0]

			dispatch({
				type: Types.USER_DETAILS,
				userData
			})
		})
		.catch(error => {
			manageError(error)
		})
}

export const retrieveAffiliates = (data) => dispatch => {
	axios.post('/user/list', data)
		.then((res) => {
			let responseData = res.data

			dispatch({
				type: Types.AFFILIATE_RETRIEVE,
				payload: {
					response: responseData,
					error: {}
				}
			})
		})
		.catch(error => {
			manageError(error)
		})
}

export const registerAffiliates = (data, history, stopLoading, resetFormData) => dispatch => {
	axios.post('/user/register-affiliate', data)
		.then((res) => {
			let userInfo = res.data.data

			stopLoading()
			resetFormData()

			dispatch({
				type: Types.AFFILIATE_NEW,
				payload: {
					userInfo,
					error: {}
				}
			})
			showSuccess('Successfully created');
		})
		.catch(error => {
			stopLoading()
			manageError(error)
		})
}

export const updateUserAction = (data, history, stopLoading, resetFormData) => dispatch => {
	axios.post('/user/update', data)
		.then((res) => {
			let userInfo = res.data.data

			stopLoading()
			resetFormData()

			// dispatch({
			// 	type: Types.AFFILIATE_NEW,
			// 	payload: {
			// 		userInfo,
			// 		error: {}
			// 	}
			// })
			showSuccess('Successfully updated');
		})
		.catch(error => {
			stopLoading()
			manageError(error)
		})
}

export const deleteUserAction = (data, history, stopLoading, resetFormData) => dispatch => {
	axios.post('/user/delete', data)
		.then((res) => {
			let userInfo = res.data.data

			stopLoading()
			showSuccess('Successfully deleted');
			history.push('/users')

		})
		.catch(error => {
			stopLoading()
			manageError(error)
		})
}