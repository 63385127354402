import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import PrimaryMenu from '../../components/menu/headerPrimaryMenu'
import SideMenu from '../../components/menu/sideMenu'

import PageBreadcrumb from '../../components/default/pageBreadcrumb'
import setPageData from '../../store/actions/setPageData'
import NewPromoForm from '../../components/promoCode/NewPromoForm';
import NewAffiliateForm from '../../components/affiliate/NewAffiliateForm';

class NewAffiliatePage extends React.Component {
	state = {
		error: {},
		isLoading: false,
	}


	static getDerivedStateFromProps(nextProps, prevProps) {
		return null
	}

	stopLoading = () => {
		this.setState({ isLoading: false })
	}

	_setPageData() {
		this.props.setPageData({
			title: 'New Affiliate',
			pageID: 'affiliates'
		});
	}

	componentDidMount() {
		this._setPageData()
	}

	render() {
		let { pageData } = this.props


		return (
			<>
				<Helmet> <title>{pageData.title}</title> </Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row">
						<SideMenu />
						<div className="col-md-10">
							<div className="container new-order">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
								</div>

								<div className="row">


									<div className="col-md-7">
										<div className="page-content">
											<div className="insert-new">
												<NewAffiliateForm />
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	serviceData: state.serviceData,
})
const mapDispatchToProps = ({
	setPageData
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewAffiliatePage))