import React from 'react'
import { connect } from 'react-redux'
import { registerAffiliates } from '../../store/actions/userActions';


class NewAffiliateForm extends React.Component {
	state = {
		firstName: '',
		lastName: '',
		email: '',
		error: {},
		isLoading: false,
	}

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null
	}
	stopLoading = () => {
		this.setState({ isLoading: false })
	}
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	resetFormData = () => {
		this.setState({
			firstName: '',
			lastName: '',
			email: '',
		});
	}

	submitHandler = async (event) => {
		event.preventDefault()

		this.setState({ isLoading: true })

		let { firstName, lastName, email } = this.state

		this.props.registerAffiliates({ firstName, lastName, email }, this.props.history, this.stopLoading, this.resetFormData)
	}

	componentDidMount() {
		let { preData } = this.props

		// set default info if data provided
		if (preData) {
			this.setState({
				promoAuthor: preData.promoAuthor,
			})
		}
	}

	render() {
		let { firstName, lastName, email, isLoading, error } = this.state

		return (
			<form
				onSubmit={this.submitHandler}
				action="">
				<div className="row">
					<div className="col-md-12">

						<div className="single-section">
							<div className="section-title">
								<h4>Affiliate details</h4>
							</div>


							<div className="form-group has-error">
								<label className="control-label" htmlFor="firstName">First name</label>
								<input
									type="text"
									className={
										(error.firstName ? "form-control is-invalid" : "form-control")
									}
									placeholder=""
									value={firstName}
									name="firstName"
									id="firstName"
									value={this.state.firstName}
									onChange={this.changeHandler}
								/>
								{error.firstName && (
									<div className="invalid-feedback">
										{error.firstName}
									</div>
								)}
							</div>
							<div className="form-group has-error">
								<label className="control-label" htmlFor="lastName">Last name</label>
								<input
									type="text"
									className={
										(error.lastName ? "form-control is-invalid" : "form-control")
									}
									value={lastName}
									placeholder=""
									name="lastName"
									id="lastName"
									value={this.state.lastName}
									onChange={this.changeHandler}
								/>
								{error.lastName && (
									<div className="invalid-feedback">
										{error.lastName}
									</div>
								)}
							</div>
							<div className="form-group has-error">
								<label className="control-label" htmlFor="email">Email</label>
								<input
									type="email"
									className={
										(error.email ? "form-control is-invalid" : "form-control")
									}
									value={email}
									placeholder=""
									name="email"
									id="email"
									value={this.state.email}
									onChange={this.changeHandler}
								/>
								{error.email && (
									<div className="invalid-feedback">
										{error.email}
									</div>
								)}
							</div>

							<div className="form-actions text-right">
								<button
									type={isLoading ? 'button' : 'submit'}
									className="btn btn-primary is-invalid"> {isLoading ? (<i className="fas fa-spinner fa-spin"></i>) : 'Add affiliate'} </button>
							</div>
						</div>
					</div>
				</div>
			</form>
		)
	}

}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
})
const mapDispatchToProps = ({
	registerAffiliates
})
export default connect(mapStateToProps, mapDispatchToProps)(NewAffiliateForm)