import axios from 'axios';

const configureAxios = async (token) => {

	// axios.defaults.baseURL = 'http://localhost/prism/sap/api';
	axios.defaults.baseURL = 'https://sap.prismict.com/api';

	let authToken = await localStorage.getItem('authToken')
	setAuthToken(authToken)
	return true
}


export const setAuthToken = async (token) => {
	if (token) {
		axios.defaults.headers.common['Authorization'] = token;
	} else {
		axios.defaults.headers.common['Authorization'] = '';
	}
}

export default configureAxios
