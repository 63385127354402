import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/authActions'
import ReactPaginate from 'react-paginate';
import Icofont from 'react-icofont';
import { ViewDate, ViewExactDate } from '../../utils/DateViewer'


import bodyClassController from '../../store/utils/bodyClassController'
import PrimaryMenu from '../../components/menu/headerPrimaryMenu'
import SideMenu from '../../components/menu/sideMenu'
import PageBreadcrumb from '../../components/default/pageBreadcrumb'
import NewPromoModal from '../../components/promoCode/NewPromoModal'

import setPageData from '../../store/actions/setPageData'
import { retrieveUsers } from '../../store/actions/userActions'

class UsersPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ReOrderModalOpen: null,
			expandedItem: null,
			NewTicketModalOpen: false,
			accountType: '',
			autoRenew: false,
			paymentStatus: 'activeSubscription',
			advanceFilter: false,
			orderBy: 'date',
			orderSort: 'DESC',
			promoCode: '',
			keyword: '',
			error: {},
			page: 1,
		}
	}
	changeHandler = async (event) => {
		let stateName, stateValue;
		if (event.target.type == 'checkbox') {
			stateName = event.target.name;
			stateValue = event.target.checked;
		} else {
			stateName = event.target.name;
			stateValue = event.target.value;
		}


		this.setState({
			[stateName]: stateValue
		}, () => {
			console.log('type', event.target.type);
			if (event.target.type === 'checkbox' || event.target.type === 'select-one') {
				this.submitHandler()
			}
		})
	}

	submitHandler = event => {
		if (event != null) {
			event.preventDefault()
		}

		let { page, promoCode, keyword, accountType, orderBy, orderSort, autoRenew, paymentStatus } = this.state
		this.props.retrieveUsers({
			page,
			promoCode,
			keyword,
			accountType,
			orderBy,
			orderSort,
			paymentStatus,
			autoRenew
		})
	}

	setPageData() {
		this.props.setPageData({
			title: 'All users',
			pageID: 'users'
		});
	}

	// set data sorting //
	setSorting = (orderBy, orderSort) => {
		this.setState({ orderBy, orderSort }, () => {
			this.submitHandler()
		})
	}

	componentDidMount() {
		this.setPageData()
		this.submitHandler()
	}

	componentWilMount() {
	}

	handlePagination = data => {
		this.setState({
			page: data.selected + 1,
		}, () => {
			// this.props.retrieveUsers({ page: this.state.page })
			this.submitHandler()
		});
	}

	render() {
		this.props.bodyClassController(this.props.pageData)

		let { userData, pageData } = this.props
		let { promoCode, keyword, expandedItem, orderBy, orderSort, autoRenew, advanceFilter, paymentStatus } = this.state
		let allUsers, userPagination, showPagination;
		if (userData && userData.data) {
			allUsers = userData.data.length ? userData.data : null
			userPagination = userData.pagination ? userData.pagination : null

			showPagination = userPagination.page_last > 1 ? true : false
		}

		return (
			<>
				<Helmet> <title>{pageData.title}</title> </Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
								</div>

								<div className="page-content">
									<div className="table-area table-responsive">
										<div className="table-filter">
											<form action="" onSubmit={this.submitHandler}>
												<div className="row">
													<div className="col-md-12">


														<input type="text" id="keyword" className="ml-1"
															name="keyword"
															value={keyword}
															onChange={this.changeHandler}
															placeholder="Search.." />
														<button type="submit" className="ml-1 btn-primary"><i className="fa fa-search" /></button>
														<button type="button"
															onClick={() => {
																if (advanceFilter) {
																	this.setState({
																		advanceFilter: false
																	})
																} else {
																	this.setState({
																		advanceFilter: true
																	})
																}
															}}
															className="ml-1 btn-default"><i className="fa fa-filter" /></button>
													</div>
													<div className="col-md-12">
														{advanceFilter &&
															<div className="filter-advance">
																<div className="row">
																	<div className="col-sm-4">
																		<div className="form-group row mb-0">
																			<label for="userPromoCode" className="col-sm-4 col-5 col-form-label">Promo code</label>
																			<div className="col-sm-8  col-7">
																				<input type="text" className="form-control-sm" id="userPromoCode"
																					name="promoCode"
																					value={promoCode}
																					onChange={this.changeHandler} placeholder="Promo code.." />
																			</div>
																		</div>
																	</div>
																	<div className="col-sm-4">
																		<div className="form-group row mb-0">
																			<label for="autoRenew" className="col-md-5 col-6 col-form-label">Auto Renew</label>
																			<div className="col-md-4 col-2 align-self-center">
																				<input type="checkbox" id="autoRenew"
																					name="autoRenew"
																					value={autoRenew}
																					defaultChecked={autoRenew}
																					onChange={this.changeHandler} />
																			</div>
																		</div>
																	</div>
																	<div className="col-sm-4">
																		<div className="form-group row mb-0">
																			<label for="paymentStatus" className="col-md-6 col-5 col-form-label">Subscription type</label>
																			<select className="col-md-4 col-4 align-self-center"
																				id="paymentStatus"
																				name="paymentStatus"
																				value={paymentStatus}
																				onChange={this.changeHandler} >
																				<option value=''>All</option>
																				<option value='activeSubscription'>Active subscription</option>
																				<option value='paid'>Paid</option>
																				<option value='trail'>Trail</option>
																			</select>
																		</div>
																	</div>
																</div>
															</div>
														}
													</div>
												</div>


											</form>
										</div>
										<table className="table data-table table-hover table-light">
											<thead>
												<tr>
													<th scope="col">name</th>
													<th scope="col">ID</th>
													<th scope="col">email</th>
													<th scope="col">date
														<span className={`sortable ${orderBy == 'date' ? 'active' : ''}`} onClick={() => { this.setSorting('date', orderBy == 'date' ? orderSort == 'ASC' ? 'DESC' : 'ASC' : 'DESC') }}>
															{orderBy == 'date' && orderSort == 'ASC' ?
																<i className="fa fa-sort-amount-up"></i> : <i className="fa fa-sort-amount-down"></i>
															}
														</span>
													</th>
													<th scope="col" style={{ minWidth: '130px' }}>expire on
														<span className={`sortable ${orderBy == 'planExpireDate' ? 'active' : ''}`} onClick={() => { this.setSorting('planExpireDate', orderBy == 'planExpireDate' ? orderSort == 'ASC' ? 'DESC' : 'ASC' : 'DESC') }}>
															{orderBy == 'planExpireDate' && orderSort == 'ASC' ?
																<i className="fa fa-sort-amount-up"></i> : <i className="fa fa-sort-amount-down"></i>
															}
														</span>
													</th>
													<th scope="col">promo</th>
													<th scope="col">Author</th>
												</tr>
											</thead>

											<tbody>
												{
													allUsers ? allUsers.map(item => {
														let userDisplayName = item.name ? item.name : `${item.firstName} ${item.lastName}`
														// userDisplayName += item.planpaymentStatus ? '- (Trail)' : ``

														let refDisplayName = item.referBy ? `${item.refFirstName} ${item.refLastName}` : '--'
														let promoCode = item.promoCode ? item.promoCode : '--'
														return (
															<tr
																key={item.UserID}
																onClick={() => this.setState({ 'expandedItem': item.UserID })}
																className={` single-item ${expandedItem == item.UserID ? 'expanded' : ''}`} >

																<td data-label={`${expandedItem == item.UserID ? 'Name' : '#' + item.UserID}`}>
																	<Link to={`/users/${item.UserID}/update`}>{userDisplayName}</Link>
																</td>
																<td data-label="UserID">{`#${item.UserID}`}</td>
																<td data-label="Email">{item.email}</td>
																<td data-label="Register"><ViewDate date={item.date} /></td>
																<td data-label="Expire on">{item.planExpireDate != null ? <ViewExactDate date={item.planExpireDate} format='Do MMM YYYY' /> : '--'}</td>
																<td data-label="Promo">{promoCode}</td>
																<td data-label="Author">{refDisplayName}</td>
															</tr>
														)
													})
														: ''
												}
											</tbody>
										</table>
										<div className="section-pagination">
											<font>Showing {userPagination?.data_start} to {userPagination?.data_end} out of {userPagination?.data_total}</font>
											{showPagination &&
												<nav
													className="pagination-nav"
													aria-label="...">
													<ReactPaginate
														previousLabel={'previous'}
														nextLabel={'next'}
														breakLabel={'...'}
														breakClassName={'break-me'}
														pageCount={userPagination.page_last}
														initialPage={(userPagination.page - 1)}
														marginPagesDisplayed={2}
														pageRangeDisplayed={5}
														containerClassName={'pagination'}
														previousClassName={'page-item'}
														nextClassName={'page-item'}
														pageClassName={'page-item'}
														subContainerClassName={'pages pagination'}
														activeClassName={'active'}
														pageLinkClassName={'page-link'}
														previousLinkClassName={'page-link'}
														nextLinkClassName={'page-link'}
														onPageChange={this.handlePagination}
													/>
												</nav>
											}

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}


	openReOrderModal = orderInfo => {
		this.setState({
			ReOrderModalOpen: orderInfo
		})
	}
	closeReOrderModal = () => {
		this.setState({
			ReOrderModalOpen: null
		})
	}



	openNewTicketModal = (item) => {
		this.setState({
			NewTicketModalOpen: item.OrderID
		})
	}

	closeNewTicketModal = () => {
		this.setState({
			NewTicketModalOpen: false
		})
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
})
const mapDispatchToProps = ({
	logout,
	setPageData,
	bodyClassController,
	retrieveUsers,
})
export default connect(mapStateToProps, mapDispatchToProps)(UsersPage)